import { Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { OpenIDContext } from '../../api/auth'
import { WebsocketContext } from '../../api/hooks'
import SecDimAlert from '../../shared/components/SecDimAlert/SecDimAlert'

function GlobalAlerts() {
	const { authenticated, profile } = useContext(OpenIDContext)
	const { isWSConnectionEstablished, wsConnectionMessage } =
		useContext(WebsocketContext)
	const [alertShown, setAlertShown] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [alertLink, setAlertLink] = useState('')
	const [severity, setSeverity] = useState('error')
	const subscriptionCheckout = sessionStorage.getItem('subscriptionCheckout')

	useEffect(() => {
		if (
			authenticated &&
			subscriptionCheckout &&
			subscriptionCheckout === 'success'
		) {
			setAlertMessage(
				'You have successfully subscribed. It may take a few minutes for your subscription to be applied.',
			)
			setAlertLink('')
			setSeverity('success')
			setAlertShown(true)
			sessionStorage.removeItem('subscriptionCheckout')
		} else if (authenticated && profile.isSubscribed) {
			if (profile.restrictions.subscribedRemainingAttempts === 0) {
				setAlertMessage('You have run out of challenge attempts.')
				setAlertLink('Please upgrade your subscription plan')
				setSeverity('error')
				setAlertShown(true)
			} else if (profile.restrictions.subscribedRemainingAttempts < 3) {
				setAlertMessage(
					`You have ${profile.restrictions.subscribedRemainingAttempts} challenge attempts left.`,
				)
				setAlertLink('Please upgrade your subscription plan')
				setSeverity('error')
				setAlertShown(true)
			}
		}
		if (!('Notification' in window)) {
			setAlertMessage('This browser does not support desktop notification')
			setAlertLink('')
			setSeverity('error')
			setAlertShown(true)
		}
	}, [
		authenticated,
		isWSConnectionEstablished,
		profile.isSubscribed,
		profile.restrictions.subscribedRemainingAttempts,
		subscriptionCheckout,
		wsConnectionMessage,
	])

	return (
		<SecDimAlert
			isAlertShown={alertShown}
			setAlertShown={setAlertShown}
			severity={severity}
			alertMessage={
				<>
					<Typography>{alertMessage}</Typography>
					<Typography>{alertLink}</Typography>
				</>
			}
		/>
	)
}

export default GlobalAlerts
