import { IconButton } from '@mui/material'
import { CustomContentProps, useSnackbar } from 'notistack'
import { forwardRef, useCallback } from 'react'
import * as Styled from './SecDimStackAlertStyles'

const SecDimStackAlert = forwardRef<HTMLDivElement, CustomContentProps>(
	({ id, ...props }, ref) => {
		const { closeSnackbar } = useSnackbar()

		const handleClose = useCallback(() => {
			closeSnackbar(id)
		}, [id, closeSnackbar])

		return (
			<Styled.CustomSnackbarContent ref={ref}>
				<Styled.CustomCard>
					<Styled.CustomCardActions>
						<Styled.CustomTypography>{props.message}</Styled.CustomTypography>
						<IconButton size='small' onClick={handleClose}>
							<Styled.CloseIcon />
						</IconButton>
					</Styled.CustomCardActions>
				</Styled.CustomCard>
			</Styled.CustomSnackbarContent>
		)
	},
)

SecDimStackAlert.displayName = 'Alert'

export default SecDimStackAlert
