import { Close } from '@mui/icons-material'
import { Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'

export const CloseIcon = styled(Close)(() => ({
	cursor: 'pointer',
	marginRight: '8px',
	marginTop: '4px',
	fontSize: '25px',
}))

export const AlertSnackbar = styled(Snackbar)(() => ({}))

export const CustomAlert = styled(Alert)(() => ({
	marginTop: '25px',
	'& .MuiAlert-icon': {
		marginRight: '8px',
		marginTop: '4px',
	},
}))
