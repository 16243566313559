import { Close } from '@mui/icons-material'
import { Card, CardActions, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { SnackbarContent } from 'notistack'

export const CloseIcon = styled(Close)(({ theme }) => ({
	cursor: 'pointer',
	fontSize: '25px',
	color: theme.palette.common.white,
}))

export const CustomSnackbarContent = styled(SnackbarContent)(() => ({}))

export const CustomCard = styled(Card)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
}))

export const CustomCardActions = styled(CardActions)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
}))

export const CustomTypography = styled(Typography)(({ theme }) => ({
	width: '250px',
	paddingLeft: '20px',
	color: theme.palette.common.white,
	fontWeight: 600,
}))
