import * as Styled from './SecDimAlertStyles'

function SecDimAlert({
	severity,
	alertMessage,
	sx
}: SecDimDialogAlertProps) {
	return (
		<Styled.CustomAlert severity={severity} sx={sx}>
			{alertMessage}
		</Styled.CustomAlert>
	)
}

export default SecDimAlert
