import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LoaderOuterWrapper = styled(Box)<{ overlay: string }>(
	({ overlay, theme }) => ({
		position: 'absolute',
		top: 0,
		left: 0,
		backgroundColor:
			overlay === 'true' ? 'rgba(255, 255, 255, 0.5) !important' : 'transparent',
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '20px',
		overflow: 'hidden',
		zIndex: overlay ? 2 : 1,

		'& .MuiCircularProgress-colorPrimary': {
			color: theme.palette.primary.main,
		},
	}),
)

export const LoaderInnerWrapper = styled(Box)<{ overlay: string }>(
	({ overlay, theme }) => ({
		backgroundColor:
			overlay === 'true' ? 'rgba(255, 255, 255, 0.5) !important' : 'transparent',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',

		'& .MuiCircularProgress-colorPrimary': {
			color: theme.palette.primary.main,
		},
	}),
)

export const HideWrapper = styled(Box)<{ hide: string }>(({ hide }) => ({
	display: hide === 'true' ? 'none' : 'initial',
}))

export const LogoWrapper = styled(Box)({
	marginRight: '2.5rem',
	transform: 'translateY(6px)',
})

export const Message = styled(Typography)({
	padding: '10px 0',
})
