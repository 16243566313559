import { useContext, useEffect, useState } from 'react';
import { OpenIDContext } from '../../../../api/auth';
import { useCompanyDashboardContext, useCompanyPlayerGitHistoryNotificationWS, useWebsocketContext } from '../../../../api/hooks';
import playBackendRequestHandler from '../../../../api/playBackendRequestHandler';
import SecDimButton from '../../../../shared/components/SecDimButton/SecDimButton';
import Tooltip from '../../../../shared/components/Tooltip/Tooltip';
import theme from '../../../../utils/theme';
import CompanyPlayersActivitiesPieChart from '../CompanyPlayersActivitiesPieChart/CompanyPlayersActivitiesPieChart';
import * as Styled from './CompanyPlayerActivityDialogStyles';
import CompanyPlayerDepartmentDialog from './components/CompanyPlayerDepartmentDialog/CompanyPlayerDepartmentDialog';
import CompanyPlayerGitHeatmap from './components/CompanyPlayerGitHeatmap/CompanyPlayerGitHeatmap';
import CompanyPlayerRepositoriesTable from './components/CompanyPlayerRepositoriesTable/CompanyPlayerRepositoriesTable';
import RemoveCompanyPlayerConfirmationDialog from './components/RemoveCompanyPlayerConfirmationDialog/RemoveCompanyPlayerConfirmationDialog';
import SecDimAlert from '../../../../shared/components/SecDimAlert/SecDimAlert';

function CompanyPlayerActivityDialog({
	className,
	companyPlayersActivitiesPieChartData,
	departments,
	refetchCompanyData,
	refetchCompanyDashboardData
}: CompanyPlayerActivityDialogProps) {
	const [openRemoveCompanyPlayerConfirmationDialogInfo, setOpenRemoveCompanyPlayerConfirmationDialogInfo] = useState<CompanyPlayerActivityInfo>({ isDialogShown: false, playerUsername: "", playerEmail: "", playerGuid: "" })
	const [openAssignDepartmentDialogInfo, setOpenAssignDepartmentDialogInfo] = useState<CompanyPlayerActivityInfo>({ isDialogShown: false, playerUsername: "", playerEmail: "", playerGuid: "" })
	const { openCompanyPlayerProfileDialogInfo, setOpenCompanyPlayerProfileDialogInfo } = useCompanyDashboardContext()
	const { isWSConnectionEstablished } = useWebsocketContext() // this is global ws connection variable
	const { companyPlayerGitHistoryWS, setIsWSConnectionEstablished } = useCompanyPlayerGitHistoryNotificationWS()
	const [companyPlayerHeatMap, setCompanyPlayerHeatMap] = useState<HeatMap[]>([])
	const [permissionAlert, setPermissionAlert] = useState<string>('')
	const [permissionAlertOpen, setPermissionAlertOpen] = useState(false)
    const { profile } = useContext(OpenIDContext)

	const handleCloseDialog = () => {
		setOpenCompanyPlayerProfileDialogInfo({
			isDialogShown: false,
			playerUsername: "",
			playerEmail: "",
			playerGuid: "",
		})
	}

	const handleCloseRemoveCompanyPlayerConfirmationDialog = () => {
		setOpenRemoveCompanyPlayerConfirmationDialogInfo({
			isDialogShown: false,
			playerUsername: "",
			playerEmail: "",
			playerGuid: "",
		})
	}

	const handleRemoveUserButton = () => {
		setOpenRemoveCompanyPlayerConfirmationDialogInfo({
			isDialogShown: true,
			playerUsername: openCompanyPlayerProfileDialogInfo.playerUsername,
			playerEmail: openCompanyPlayerProfileDialogInfo.playerEmail,
			playerGuid: openCompanyPlayerProfileDialogInfo.playerGuid,
		})
	}

	const handleUnassignDepartmentButton = () => {
		if (profile.restrictions.hasAddCompanyDepartmentPermission) {
			const users: { guid: string; department: string | null }[] = []
			users.push({
				guid: openCompanyPlayerProfileDialogInfo.playerGuid,
				department: null,
			})
			const data = {
				username: profile.guid,
				users,
			}
			playBackendRequestHandler(
				'batchUserUpdate',
				data,
				undefined,
				undefined,
			).then(resp => {
				if (resp.status === 200) {
					refetchCompanyDashboardData()
				}
			})
		} else {
			setPermissionAlertOpen(true)
			setPermissionAlert('Upgrade your account to manage company departments.')
		}
	}

	const handleCloseAssignDepartmentDialog = () => {
		setOpenAssignDepartmentDialogInfo({
			isDialogShown: false,
			playerUsername: "",
			playerEmail: "",
			playerGuid: "",

		})
	}

	const handleAssignDepartmentButton = () => {
		if (profile.restrictions.hasAddCompanyDepartmentPermission) {
			setOpenAssignDepartmentDialogInfo({
				isDialogShown: true,
				playerUsername: openCompanyPlayerProfileDialogInfo.playerUsername,
				playerEmail: openCompanyPlayerProfileDialogInfo.playerEmail,
				playerGuid: openCompanyPlayerProfileDialogInfo.playerGuid,
			})
		} else {
			setPermissionAlertOpen(true)
			setPermissionAlert("Upgrade your account to manage company departments.")
		}
	}

	const makeCompanyPlayerGitHistoryApiCall = (selectedPlayerUsername: string, selectedPlayerEmail: string) => playBackendRequestHandler(
		'companyPlayerGitHistory',
		undefined,
		undefined,
		`?company_player_username=${selectedPlayerUsername}&company_player_email=${selectedPlayerEmail}`
	)

	useEffect(() => {
		if (isWSConnectionEstablished && companyPlayerGitHistoryWS) {
			// set heatmap once it arrives through ws
			setCompanyPlayerHeatMap(companyPlayerGitHistoryWS)
		}
	}, [companyPlayerGitHistoryWS, isWSConnectionEstablished])

	useEffect(() => {
		// set setIsWSConnectionEstablished value true, once ws is globally established (isWSConnectionEstablished=true)
		if (isWSConnectionEstablished) {
			setIsWSConnectionEstablished(isWSConnectionEstablished)
			makeCompanyPlayerGitHistoryApiCall(openCompanyPlayerProfileDialogInfo.playerUsername, openCompanyPlayerProfileDialogInfo.playerEmail)
		}
	}, [isWSConnectionEstablished, setIsWSConnectionEstablished, openCompanyPlayerProfileDialogInfo])

	return (
		<>
			{openRemoveCompanyPlayerConfirmationDialogInfo.isDialogShown && <RemoveCompanyPlayerConfirmationDialog openRemoveCompanyPlayerConfirmationDialogInfo={openRemoveCompanyPlayerConfirmationDialogInfo} handleCloseRemoveCompanyPlayerConfirmationDialog={handleCloseRemoveCompanyPlayerConfirmationDialog} />}
			{openAssignDepartmentDialogInfo.isDialogShown && <CompanyPlayerDepartmentDialog openAssignDepartmentDialogInfo={openAssignDepartmentDialogInfo} handleCloseAssignDepartmentDialog={handleCloseAssignDepartmentDialog} departments={departments} refetchCompanyData={refetchCompanyData} />}
			<Styled.CustomDialog maxWidth="lg" onClose={handleCloseDialog} open={openCompanyPlayerProfileDialogInfo.isDialogShown} className={className}>
				<Styled.AbsoluteClose onClick={handleCloseDialog} />
				<Styled.PlayerActivityDialogContainer container direction="column">
					<SecDimAlert
						isAlertShown={permissionAlertOpen}
						setAlertShown={setPermissionAlertOpen}
						severity='error'
						alertMessage={permissionAlert}
					/>
					<Styled.PlayerActivityGridDialogItem item sx={{ paddingBottom: '10px' }}>
						<Styled.DialogTitleText>{openCompanyPlayerProfileDialogInfo.playerEmail} &#40;{openCompanyPlayerProfileDialogInfo.playerUsername}&#41;</Styled.DialogTitleText>
					</Styled.PlayerActivityGridDialogItem>
					<Styled.PlayerActivityGridDialogItem item>
						<CompanyPlayerGitHeatmap companyPlayerHeatMap={companyPlayerHeatMap} />
					</Styled.PlayerActivityGridDialogItem>
					<Styled.PlayerActivityGridDialogItem item >
						<Styled.PlayerActivityGraphTableContainer container direction="row">
							<Styled.PlayerActivityGraphTableItem item xs={12} sm={4}>
								<CompanyPlayersActivitiesPieChart companyPlayersActivitiesPieChartData={companyPlayersActivitiesPieChartData.filter((item: CompanyPlayersActivitiesPieChart) => item.player_username === openCompanyPlayerProfileDialogInfo.playerUsername)} />
							</Styled.PlayerActivityGraphTableItem>
							<Styled.PlayerActivityGraphTableItem item xs={12} sm={8}>
								<Styled.PlayerRankingsTitle variant='h4'>Player Repositories
									<Tooltip title="Click on each row to view the user challenge code repository"
										placement='top-start'>
										<Styled.HelpIcon />
									</Tooltip>
								</Styled.PlayerRankingsTitle>
								<CompanyPlayerRepositoriesTable />
							</Styled.PlayerActivityGraphTableItem>
						</ Styled.PlayerActivityGraphTableContainer>
					</Styled.PlayerActivityGridDialogItem>
					<Styled.PlayerActivityDialogUserActionsContainer container direction="row">
						<Styled.PlayerActivityGridDialogItem item >
							<Styled.RemoveButton variant="outlined" onClick={handleRemoveUserButton}>
								Remove User
							</Styled.RemoveButton>
						</Styled.PlayerActivityGridDialogItem>
						<Styled.PlayerActivityGridDialogItem item >
							<Styled.RemoveButton variant="outlined" onClick={handleUnassignDepartmentButton}>
								Remove Department
							</Styled.RemoveButton>
						</Styled.PlayerActivityGridDialogItem>
						<Styled.PlayerActivityGridDialogItem item >
							<SecDimButton rounded variant="outlined"
								sx={{
									backgroundColor: theme.palette.common.white,
									color: theme.palette.primary.main,
									'&:hover': {
										backgroundColor: theme.palette.primary.main,
										color: theme.palette.common.white,
									}
								}}
								onClick={handleAssignDepartmentButton}
							>Assign Department</SecDimButton>
						</Styled.PlayerActivityGridDialogItem>
					</Styled.PlayerActivityDialogUserActionsContainer>
				</Styled.PlayerActivityDialogContainer>
			</Styled.CustomDialog >
		</>
	)
}
export default CompanyPlayerActivityDialog
