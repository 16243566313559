import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router } from 'react-router-dom'
import OpenIDContextProvider from './components/OpenIDContextProvider/OpenIDContextProvider'
import PlayRouter from './components/PlayRouter/PlayRouter'
import DisplayTrial from './shared/components/DisplayTrial/DisplayTrial'
import SecDimStackAlert from './shared/components/SecDimStackAlert/SecDimStackAlert'

const qClient = new QueryClient()

declare module 'notistack' {
	// eslint-disable-next-line no-unused-vars
	interface VariantOverrides {
		secdimStackAlert: true
	}
}

function App() {
	return (
		<OpenIDContextProvider className='full-height'>
			<QueryClientProvider client={qClient}>
				<ReactQueryDevtools initialIsOpen={false} />
				<DisplayTrial />
				<SnackbarProvider
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					Components={{
						secdimStackAlert: SecDimStackAlert,
					}}
				/>
				<Router>
					<PlayRouter />
				</Router>
			</QueryClientProvider>
		</OpenIDContextProvider>
	)
}

export default App
