import * as Styled from './SecDimAlertStyles'

function SecDimAlert({
	isAlertShown,
	setAlertShown,
	severity,
	alertMessage,
	sx
}: SecDimAlertProps) {
	return (
		<Styled.AlertSnackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			sx={{ marginTop: '50px', ...sx  }}
			open={isAlertShown}
		>
			<Styled.CustomAlert
				onClick={() => setAlertShown(false)}
				severity={severity}
				action={<Styled.CloseIcon fontSize='inherit' />}
			>
				{alertMessage}
			</Styled.CustomAlert>
		</Styled.AlertSnackbar>
	)
}

export default SecDimAlert
