import { useState } from 'react'
import { BackgroundColorContext } from '../../api/hooks'
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader'
import Loader from '../../shared/components/Loader/Loader'
import Footer from '../Footer/Footer'
import GlobalAlerts from '../GlobalAlerts/GlobalAlerts'
import Header from '../Header/Header'
import * as Styled from './PageStyles'

function Page({ className, page }: PageProps) {
	const [backgroundColor, setBackgroundColor] = useState('')
	const code = new URLSearchParams(window.location.search).get('code')
	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<BackgroundColorContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{ backgroundColor, setBackgroundColor }}
		>
			<Styled.MainContainer className={className} bgcolor={backgroundColor}>
				<Styled.StrangeFlex>
					<Header notifs={0} />
					<Styled.PageContainer>
						<GlobalAlerts />
						<FullPageLoader>
							{code ? <Loader message='Please wait, signing in...' /> : page}
						</FullPageLoader>
					</Styled.PageContainer>
					<Footer />
				</Styled.StrangeFlex>
			</Styled.MainContainer>
		</BackgroundColorContext.Provider>
	)
}

export default Page
